import Screen from '../components/Screen'
import { PageProps } from 'gatsby'
import { useState } from 'react'
import Carousel, { Modal, ModalGateway } from 'react-images'

const photos = [
  'sdh/P5168575.jpg',
  'sdh/P5168576.jpg',
  'sdh/zavod.jpg',
  'sdh/P5168579.jpg',
  'sdh/historicke_stroje.jpg',
  'sdh/P5168517.jpg',
]

const oldPhotos = ['sdh/pozarnici.jpg', 'sdh/pozarnici2.jpg', 'sdh/stavklu.jpg']

const Sdh = (props: PageProps) => {
  const [currentImage, setCurrentImage] = useState('')
  const [viewerIsOpen, setViewerIsOpen] = useState(false)

  const openLightbox = (id: string) => () => {
    setCurrentImage(id)
    setViewerIsOpen(true)
  }

  const closeLightbox = () => {
    setCurrentImage('')
    setViewerIsOpen(false)
  }

  return (
    <Screen {...props}>
      <div className="flex flex-col lg:flex-row">
        <div className="w-full lg:w-2/4 lg:pr-12 pt-6">
          <h2 className="h2-medium divider pb-4 mb-4 mt-0">
            Sbor dobrovolných hasičů Lipovka
          </h2>
          <p className="p1">
            Náš sbor patří ke Sdružení hasičů Čech, Moravy a Slezska k okrsku
            č.1 Rychnov nad Kněžnou.
          </p>
          <p className="p1-medium-main">
            Členskou základnu tvoří 40 členů, z toho je 9 žen.
          </p>
          <p className="p1">
            Sídlíme v Lipovce hned vedle místní hospody U Elišky. V našem
            objektu máme klubovnu, trojgaráž na požární techniku, gril,
            sportovní hřiště, starou hasičskou zbrojnici a starou truhlárnu
            "HERBICH", kterou právě rekonstruujeme pro další účely.
          </p>
          <div className="flex flex-wrap mt-8 gap-y-4">
            {photos.map((photo) => (
              <div className="w-full lg:w-2/4 2xl:w-2/6" key={photo}>
                <img
                  src={photo}
                  className="lg:h-40 shadow-2xl cursor-pointer max-w-full"
                  onClick={openLightbox(photo)}
                />
              </div>
            ))}
          </div>
          <h2 className="h2-medium divider pb-4 mb-4 mt-16">Založení sboru</h2>
          <p className="p1">
            Ustavující valná hromada Sboru dobrovolných hasičů v Lipovce byla
            konána <strong>18. srpna 1885</strong>. Po proslovu starosty obce
            p.Jana Čižinského, přihlásilo se 22 činných členů. Prvním velitelem
            byl zvolen pan Josef Dusil.
          </p>
          <div className="flex flex-col lg:flex-row mt-8 gap-4">
            {oldPhotos.map((photo) => (
              <div
                className="w-full lg:w-2/4 2xl:w-2/6 text-center lg:text-auto"
                key={photo}
              >
                <img
                  src={photo}
                  className="h-40 shadow-2xl max-w-[287px] lg:max-w-none"
                  onClick={openLightbox(photo)}
                />
              </div>
            ))}
          </div>
        </div>
        <div className="w-full lg:w-2/4 lg:mt-0 mt-12">
          <div className="bg-zinc-200 px-6 lg:px-12 pb-8 pt-6">
            <h2 className="h2-medium divider-dark pb-4 mb-4 mt-0">
              Výbor sboru SDH Lipovka
            </h2>
            <p className="p1-medium-main">Složení nového výboru od 11.1.2020</p>
            <p className="p1">
              <strong>starosta</strong> - Zdeněk Pohl
            </p>
            <p className="p1">
              <strong>místostarosta</strong> - Hana Hovorková
            </p>
            <p className="p1">
              <strong>jednatel</strong> - Zdeněk Hovorka
            </p>
            <p className="p1">
              <strong>hospodář</strong> - Pavel Zeithammer
            </p>
            <p className="p1 mb-0">
              <strong>preventista</strong> - Jan Hovorka
            </p>
          </div>
        </div>
      </div>
      <ModalGateway>
        {viewerIsOpen && (
          <Modal onClose={closeLightbox}>
            <Carousel views={[{ source: currentImage }]} />
          </Modal>
        )}
      </ModalGateway>
    </Screen>
  )
}

export default Sdh
